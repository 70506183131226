import { useEffect } from 'react';
import ErrorMessage from '../../ErrorMessage';
import Loading from '../../Loading/Loading';
import UserMovieList from './UserMovieList';
import SearchBar from './SearchBar';
import { useAppDispatch, useAppSelector } from '../../../store';
import { AsyncStatus } from '../../../common/constants';
import { completeMovie, fetchWatchlist, FetchWatchlistArgs, manualRetry, removeMovie } from '../../../slices/watchlistSlice';
import styles from "./watchlist.module.scss";
import { useAuthToken } from '../../../common/hooks';

const Watchlist = (): JSX.Element =>
{
  const status = useAppSelector(state => state.watchlist.fetchStatus);
  const errorMessage = useAppSelector(state => state.watchlist.error);
  const movies = useAppSelector(state => state.watchlist.movies);
  const version = useAppSelector(state => state.watchlist.version);
  const dispatch = useAppDispatch();
  const authToken = useAuthToken();

  useEffect(() =>
  {
    if(authToken && status === AsyncStatus.Idle) {
      const fetchWatchlistArgs: FetchWatchlistArgs = {
        authToken: authToken
      };

      dispatch(fetchWatchlist(fetchWatchlistArgs));
    }
  }, [authToken, dispatch, status]);

  if(!authToken) {
    return <ErrorMessage message={errorMessage!} />
  }

  const onMarkedComplete = (movieId: number) => {
    dispatch(completeMovie({movieId: movieId, authToken: authToken, expectedVersion: version}));
  };

  const onDeleted = (movieId: number) => {
    dispatch(removeMovie({movieId: movieId, authToken: authToken, expectedVersion: version}));
  };

  let content = null;
  
  if(status === AsyncStatus.Succeeded) {
    if(movies && movies.length) {
      content = <UserMovieList onMarkedComplete={onMarkedComplete} onDeleted={onDeleted} movies={movies} />;
    }
    else {
      content = <div className={styles.getStartedMessage}>No movies here. Start searching and add some!</div>
    }
  }

  if(status === AsyncStatus.Loading)
  {
    content = <Loading />;
  }

  if(status === AsyncStatus.Failed)
  {
    content = (
      <>
        <ErrorMessage message={errorMessage!} />
        <button onClick={()=>dispatch(manualRetry(null))}>Retry</button>
      </>
    );
  }

  return (
    <>
      <SearchBar />
      {content}
    </>
  );
}

export default Watchlist;