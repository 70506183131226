import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface SearchIconProps {
  className?: string
}

const CheckIcon = ({className = ""}: SearchIconProps): JSX.Element => {
  return (
    <FontAwesomeIcon icon={faCheck} className="checkIcon" />
  )
}

export default CheckIcon;