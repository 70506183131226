import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { AsyncStatus } from "../../../../common/constants";
import { useAppSelector, useAppDispatch } from "../../../../store";
import ErrorMessage from "../../../ErrorMessage";
import Loading from "../../../Loading/Loading";
import { search } from "./../../../../slices/moviesSlice";
import { addMovie } from "./../../../../slices/watchlistSlice";
import styles from '../watchlist.module.scss';
import Plus from "../../../Icons/Plus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { SearchPayload } from "../../../../types";
import { useAuthToken } from "../../../../common/hooks";

const MovieSearch = (): JSX.Element =>
{
  const [queryParams] = useSearchParams();
  const searchText = queryParams.get('title');
  const [showThumbsUp, setShowThumbsUp] = useState(false);
  const movieSearchStatus = useAppSelector(state => state.movies.status);
  const addMovieStatus = useAppSelector(state => state.watchlist.addMovieStatus);
  const errorMessage = useAppSelector(state => state.movies.error);
  const searchResults = useAppSelector(state => state.movies.movies);
  const expectedVersion = useAppSelector(state => state.watchlist.version);
  const userMovieList = useAppSelector(state => state.watchlist.movies);
  const dispatch = useAppDispatch();

  if (!searchText)
  {
    throw new Error('searchText cannot be empty');
  }

  const authToken = useAuthToken();

  useEffect(() =>
  {
    if (authToken && movieSearchStatus === AsyncStatus.Idle) {
        const searchMoviePayload: SearchPayload = {
        searchText: searchText,
        authToken: authToken
      };

      dispatch(search(searchMoviePayload));
    }

  }, [authToken, searchText, dispatch, movieSearchStatus]);

  useEffect(() => 
  {
    if(addMovieStatus === AsyncStatus.Succeeded) {
      setShowThumbsUp(true);
      setTimeout(()=> {
        setShowThumbsUp(false);
      }, 1000);
    }
  }, [addMovieStatus]);

  if (movieSearchStatus === AsyncStatus.Loading)
  {
    return <Loading />
  }

  if (!authToken || movieSearchStatus === AsyncStatus.Failed)
  {
    return <ErrorMessage message={errorMessage!} />
  }

  if (!searchResults)
  {
    return <></>;
  }

  // let thumbsUpClassName = "";

  // if(showThumbsUp){
  //   thumbsUpClassName = "testing";
  // }

  return (
    <>
      <Link to="/watchlist" className="back">Back to my list</Link>
      <h3 className={styles.searchText}>Results for "{searchText}":</h3>

      <div className={styles.searchResults}>
        {
          searchResults
            .filter(searchResult =>
            {
              if(!userMovieList){
                return true;
              }

              return !userMovieList.some(movie => movie.id === searchResult.id);
            })
            .map(result =>
            {
              return (
                <div className={styles.searchResultItem} key={result.id}>
                  <div className={styles.movieTitle}>{result.title}</div>
                  <button 
                    className={styles.addMovie} 
                    onClick={() => dispatch(addMovie({ movie: result, expectedVersion: expectedVersion, authToken: authToken}))}
                  >
                    <Plus />
                  </button>
                </div>
              );
            })
        }
        <div className={showThumbsUp ? "overlay" : "hidden"}>
          <FontAwesomeIcon icon={faThumbsUp} className={showThumbsUp ? "testing" : "hidden"} />
        </div>
      </div>
    </>
  )
}

export default MovieSearch;