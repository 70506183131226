import React, { useState } from 'react';
import axios from 'axios';
import Loading from '../../Loading/Loading';
import styles from './whatsforlunch.module.scss';
import ErrorMessage from '../../ErrorMessage';

function WhatsForLunch() {
  const [restaurant, setRestaurant] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  return (
    <>
      <h2 className={styles.title}>What's For Lunch </h2>
      <div className={styles.container}>
      <button 
        className={styles.imFeelingLucky}
        disabled={isLoading}
        onClick={async ()=> {
          setIsError(false);
          setIsLoading(true);
          try {
            const result = await axios.get(`${process.env.REACT_APP_BFF_URL}/whatsforlunch/restaurants/random`);
            setRestaurant(result.data.restaurantName);
          }
          catch (error){
            console.log(error);
            setIsError(true);
          }
          setIsLoading(false);
        }}
      >
        I'm Feeling Lucky
      </button>
        <div className={isLoading || isError ? "hidden" : styles.result}>{restaurant}</div>
        {isLoading && !isError && <Loading />}
        {isError && <ErrorMessage className={styles.error} />}
      </div>
    </>
  );
}

export default WhatsForLunch;