import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../common/constants";
import Movie from "../models/Movie";
import axios, { AxiosRequestConfig } from "axios";

export interface FetchWatchlistArgs
{
  authToken: string
}

export interface FetchWatchlistResult
{
  version: number,
  movies: Movie[]
}

export const fetchWatchlist = createAsyncThunk<FetchWatchlistResult, FetchWatchlistArgs>(
  'watchlist/fetch',
  async fetchWatchlistArgs =>
  {
    const { authToken } = fetchWatchlistArgs;
    
    let requestConfig: AxiosRequestConfig<any> = {
        headers: {
          Authorization: `Bearer ${authToken}`
        },
        validateStatus: status => {return status === 404 || status === 200}
      };

    var result = await axios.get(`${process.env.REACT_APP_BFF_URL}/watchlist/mylist`, requestConfig);

    if(result.status === 404){
      return {}
    }

    return result.data;
  }
);

export interface AddMovieArgs
{
  movie: Movie,
  expectedVersion: number,
  authToken: string
}

export const addMovie = createAsyncThunk<FetchWatchlistResult, AddMovieArgs>(
  'watchlist/addMovie',
  async addMovieArgs => {
    const { movie, expectedVersion, authToken } = addMovieArgs;

    let requestConfig: AxiosRequestConfig<any> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "If-Match": expectedVersion ?? 0
      }
    };

    const content = {
      movieId: movie.id,
      movieTitle: movie.title,
      posterUrl: movie.thumbnailUrl
    }

    var result = await axios.post(`${process.env.REACT_APP_BFF_URL}/watchlist/mylist/add`, content, requestConfig);

    return result.data;
  }
);

export interface RemoveMovieArgs {
  movieId: number,
  expectedVersion: number,
  authToken: string
};

export const removeMovie = createAsyncThunk<FetchWatchlistResult, RemoveMovieArgs>(
  'watchlist/removeMovie',
  async removeMovieArgs => {
    const { movieId, expectedVersion, authToken } = removeMovieArgs;

    let requestConfig: AxiosRequestConfig<any> = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "If-Match": expectedVersion ?? 0
        }
      };

    const content = {
      movieId: movieId,
    }

    var result = await axios.post(`${process.env.REACT_APP_BFF_URL}/watchlist/mylist/remove`, content, requestConfig);

    return result.data;
  }
);

export interface CompleteMovieArgs {
  movieId: number,
  expectedVersion: number,
  authToken: string
};

export const completeMovie = createAsyncThunk<FetchWatchlistResult, CompleteMovieArgs>(
  'watchlist/completeMovie',
  async removeMovieArgs => {
    const { movieId, expectedVersion, authToken } = removeMovieArgs;

    let requestConfig: AxiosRequestConfig<any> = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "If-Match": expectedVersion ?? 0
        }
      };

    const content = {
      movieId: movieId,
    }

    var result = await axios.post(`${process.env.REACT_APP_BFF_URL}/watchlist/mylist/complete`, content, requestConfig);

    return result.data;
  }
);

export interface WatchlistState {
  version: number,
  movies: Movie[],
  fetchStatus: AsyncStatus,
  addMovieStatus: AsyncStatus,
  error: string | undefined,
};

const initialState: WatchlistState = {
  version: 0,
  movies: [],
  fetchStatus: AsyncStatus.Idle,
  addMovieStatus: AsyncStatus.Idle,
  error: undefined
};

export const watchlistSlice = createSlice({
  name: 'watchlist',
  initialState: initialState,
  reducers: {
    manualRetry(state, action) {
      state.version = 0;
      state.fetchStatus = AsyncStatus.Idle;
      state.addMovieStatus = AsyncStatus.Idle;
      state.error = undefined;
      state.movies = [];
    }
  },
  extraReducers(builder)
  {
    builder
      .addCase(fetchWatchlist.pending, (state, action) => {
        state.fetchStatus = AsyncStatus.Loading;
      }) 
      .addCase(fetchWatchlist.fulfilled, (state, action) => {
        state.fetchStatus = AsyncStatus.Succeeded;
        state.movies = action.payload.movies;
        state.version = action.payload.version;
      })
      .addCase(fetchWatchlist.rejected, (state, action) => {
        state.fetchStatus = AsyncStatus.Failed;
        //TODO: error handling
      })
      .addCase(addMovie.fulfilled, (state, action) => {
        state.addMovieStatus = AsyncStatus.Succeeded;
        state.version = action.payload.version;
        state.movies = action.payload.movies;
      })
      .addCase(addMovie.rejected, (state, action) => {
        state.addMovieStatus = AsyncStatus.Failed;
        //TODO: error handling
      }) 
      .addCase(removeMovie.fulfilled, (state, action) => {
        state.fetchStatus = AsyncStatus.Succeeded;
        state.version = action.payload.version;
        state.movies = action.payload.movies;
      })
      .addCase(removeMovie.rejected, (state, action) => {
        state.fetchStatus = AsyncStatus.Failed;
        //TODO: error handling
      })
      .addCase(completeMovie.fulfilled, (state, action) => {
        state.fetchStatus = AsyncStatus.Succeeded;
        state.version = action.payload.version;
        state.movies = action.payload.movies;
      })
      .addCase(completeMovie.rejected, (state, action) => {
        state.fetchStatus = AsyncStatus.Failed;
        //TODO: error handling
      }) 
  }
});

export const { manualRetry } = watchlistSlice.actions;
export const watchlistReducer = watchlistSlice.reducer;