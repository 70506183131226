import axios, { AxiosResponse } from 'axios';
import { all, put, takeLatest } from 'redux-saga/effects'
import { search, searchComplete } from '../slices/moviesSlice'
import { SearchAction } from '../types';

function* searchMovies({ payload }: SearchAction): Generator<any, void, AxiosResponse<any,any>> {
  const { searchText, authToken } = payload;
  const result = yield axios.get(`${process.env.REACT_APP_BFF_URL}/watchlist/movies/search?title=${encodeURIComponent(searchText)}`,
    {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    });

  yield put(searchComplete({movies: result.data.results}));
}

export default function* rootSaga() {
  yield all([
    takeLatest(search.type, searchMovies)
  ])
}