import { Link, Route, Routes } from "react-router-dom";
import App from "../App";
import { ProtectedRoute } from "./ProtectedRoute";
import Home from "./Pages/Home";
import TestPage from "./Pages/TestPage";
import WhatsForLunch from "./Pages/WhatsForLunch/WhatsForLunch";
import MovieSearch from "./Pages/Watchlist/MovieSearch/MovieSearch";
import Watchlist from "./Pages/Watchlist/Watchlist";
import WatchlistPage from "./Pages/Watchlist/WatchlistPage";

const Router = () => {

    return (
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Home />} />
            <Route path="testpage" element={<TestPage />} />
            <Route path="whatsforlunch" element={<WhatsForLunch />} />
            <Route path="watchlist" element={<ProtectedRoute component={WatchlistPage} />}>
              <Route index element={<ProtectedRoute component={Watchlist} />} />
              <Route path="search" element={<ProtectedRoute component={MovieSearch} />} />
            </Route>
            <Route path="*"
                element={
                <main>Oops! Looks like you're lost. Click <Link to="/">Here</Link> to go back home.</main>
                }
            />
          </Route>
        </Routes>
    );
};

export default Router;