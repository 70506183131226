import 'normalize.css';
import './styles/global.scss';
import './styles/small.scss';
import './styles/medium.scss';
import './styles/large.scss';

import React from 'react';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import Auth0ProviderWithRedirectCallback from './components/Auth0ProviderWithRedirectCallback';
import Router from './components/Router';
import { Provider } from 'react-redux';
import store from './store';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Auth0ProviderWithRedirectCallback
          domain={process.env.REACT_APP_AUTH_DOMAIN!}
          clientId={process.env.REACT_APP_AUTH_CLIENTID!}
          redirectUri={process.env.REACT_APP_AUTH_REDIRECT!}
          audience={process.env.REACT_APP_BFF_AUDIENCE!}
        >
          <Router />
        </Auth0ProviderWithRedirectCallback>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
