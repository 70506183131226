import { useAuth0 } from "@auth0/auth0-react";
import ActiveNavLink from "./ActiveNavLink";

interface NavigationProps {
  isVisible: boolean
}

const Navigation = ({isVisible}: NavigationProps) => {
    const {isAuthenticated} = useAuth0();

    let navClasses = ['desktop-menu-visible'];
    navClasses = navClasses.concat(isVisible ? 'mobile-menu-visible' : 'mobile-menu-hidden')

    return (
      <nav className={navClasses.join(' ')}>
        <ul>
          <li><ActiveNavLink to="/">Home</ActiveNavLink></li>
          <li><ActiveNavLink to="/testpage">Test Page</ActiveNavLink></li>
          <li><ActiveNavLink to="/whatsforlunch">What's For Lunch</ActiveNavLink></li>
          { isAuthenticated && <li><ActiveNavLink to="/watchlist">Watchlist</ActiveNavLink></li> }
        </ul>
        <div className="release-version">Version: 1.1.1</div>
      </nav>
    );
}

export default Navigation;