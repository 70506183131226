import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncStatus } from "../common/constants";
import Movie from "../models/Movie";
import { SearchPayload, SearchCompletePayload } from "../types";

export interface MoviesState
{
  movies: Movie[],
  status: AsyncStatus,
  error: string | undefined
};

const initialState: MoviesState = {
  movies: [],
  status: AsyncStatus.Idle,
  error: undefined
};

export const moviesSlice = createSlice({
  name: 'movies',
  initialState: initialState,
  reducers: {
    search(state, action: PayloadAction<SearchPayload>)
    {
      state.status = AsyncStatus.Loading;
    },
    searchComplete(state, { payload } : PayloadAction<SearchCompletePayload>)
    {
        state.status = AsyncStatus.Succeeded;
        state.movies = payload.movies;
    },
    searchFailure(state, { payload }: PayloadAction<SearchPayload>)
    {
      state.status = AsyncStatus.Failed;
      //TODO: better error handling
    }
  }
});

export const { search, searchComplete, searchFailure } = moviesSlice.actions;
export const moviesReducer = moviesSlice.reducer;