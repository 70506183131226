const MenuButton = ({onClick} : {onClick: (event: React.MouseEvent<HTMLButtonElement>) => void}) => {
    return (
        <button className="menu-button" onClick={onClick}>
            <div className="hamburger"></div>
            <div className="hamburger"></div>
            <div className="hamburger"></div>
        </button>
    );
};

export default MenuButton;
