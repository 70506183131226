const genericErrorMessage = `${"Oops! Looks like we're having problems. If it's not fixed in 10 minutes, just wait longer."}`;

const ErrorMessage = ({message, className}: {message?: string, className?: string}) => {
  if(message == null) {
    message = genericErrorMessage;
  }

  return (
    <div className={`${className} error`}>{message}</div>
  );
}

export default ErrorMessage;