import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SearchIcon = (): JSX.Element => {

  return (
    <FontAwesomeIcon icon={faMagnifyingGlass} className="searchIcon" />
  )
}

export default SearchIcon;