import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../../Icons/SearchIcon";
import styles from './watchlist.module.scss';

const SearchBar = () => {
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();

  const onSearchTextChanged = (event: React.FormEvent<HTMLInputElement>) => {
    const text = event.currentTarget.value;
    setSearchText(text);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    navigate(`/watchlist/search?title=${encodeURIComponent(searchText)}`);
  }

  return (
    <>
      <form onSubmit={handleSubmit} className={styles.searchBar}>
        <input className={styles.searchTextBox} type="text" placeholder="search movies by title" value={searchText} onChange={onSearchTextChanged} required />
        <button type="submit" className={styles.searchButton}><SearchIcon /></button>
      </form>
    </>
  );
};

export default SearchBar;