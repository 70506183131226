import React from "react";
import { Outlet } from "react-router-dom";
import styles from './watchlist.module.scss';

const WatchlistPage = () => {
  return (
  <>
    <h2 className={styles.title}>Watchlist</h2>
    <div className={styles.container}>
      <Outlet />
    </div>
  </>
  )
}

export default WatchlistPage;