import React from "react";
import Movie from "../../../models/Movie";
import CheckIcon from "../../Icons/CheckIcon";
import DeleteIcon from "../../Icons/DeleteIcon";
import styles from './watchlist.module.scss';

export interface UserMovieListProps
{
  onMarkedComplete: (movieId: number) => void,
  onDeleted: (movieId: number) => void
  movies: Movie[]
}

const UserMovieList = ({ movies, onMarkedComplete, onDeleted }: UserMovieListProps): JSX.Element =>
{
  if (!movies || movies.length < 1)
  {
    return <></>;
  }

  return (
    <>
      <ol className={styles.watchlist}>
        {
          movies.map(movie =>
          {
            return (
                <li key={movie.id}>
                  <div className={styles.watchlistItem}>
                    <div className={styles.movieTitle}>{movie.title}</div>
                    <button className={styles.complete} onClick={()=>onMarkedComplete(movie.id)}><CheckIcon /></button>
                    <button className={styles.delete} onClick={()=>onDeleted(movie.id)}><DeleteIcon /></button>
                  </div>
                </li>
            )
          })
        }
      </ol>
    </>
  );
};

export default UserMovieList;