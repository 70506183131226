import React, { PropsWithChildren } from "react"
import { NavLink, NavLinkProps } from "react-router-dom";

const getClassNameFromNavData = ({isActive}: {isActive: boolean}) => {
  return isActive ? "nav-current" : "";
};

const ActiveNavLink = ({ children, ...props } : PropsWithChildren<NavLinkProps>) => {
  
  return (
      <NavLink end className={getClassNameFromNavData} {...props}>{children}</NavLink>
  )
}

export default ActiveNavLink;