import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";

export const useAuthToken = () => {
    const [authToken, setAuthToken] = useState<string|undefined>(undefined);
    const { getAccessTokenSilently } = useAuth0();

    getAccessTokenSilently().then(token=>setAuthToken(token));

    return authToken;
}