import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DeleteIcon = (): JSX.Element => {

  return (
    <FontAwesomeIcon icon={faX} className="deleteIcon" />
  )
}

export default DeleteIcon;