import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface SearchIconProps {
  className?: string
}

const PlusIcon = ({className = ""}: SearchIconProps): JSX.Element => {

  return (
    <FontAwesomeIcon icon={faPlus} className="plusIcon" />
  )
}

export default PlusIcon;