import React from 'react';
import styles from './loading.module.scss';
import CSS from 'csstype';

const defaultStyle: CSS.Properties = {
  width: '60px',
  height: '60px',
  margin: 'auto',
  marginTop: '2em'
};

function Loading({style = defaultStyle}: {style?: CSS.Properties}) {
  return (<div className={styles.loading} style={style}></div>);
}

export default Loading;