import React, { useEffect, useRef, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

const Login = () => {
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  let [isUserContextMenuVisible, _setIsUserContextMenuVisible] = useState(false);
  const isUserContextMenuVisibleRef = useRef(isUserContextMenuVisible);

  const setIsUserContextMenuVisible = (value: boolean) => {
    isUserContextMenuVisibleRef.current = value;
    _setIsUserContextMenuVisible(value);
  };

  useEffect(()=> {
    const onDocumentClick = (event: MouseEvent) => {
      if(isUserContextMenuVisibleRef.current && event.target instanceof Element && event.target.closest('user-context-menu') === null) {
        setIsUserContextMenuVisible(false);
      }
    };

    const eventType = 'click';
    document.addEventListener(eventType, onDocumentClick)

    return () => {
      document.removeEventListener(eventType, onDocumentClick)
    };
  }, []);

    if(!isAuthenticated){
      return (
          <button className='login button' onClick={()=>loginWithRedirect()}>Login</button>
      );
    }
    
    return (
      <>
        <div className='userBadge' 
            data-initial={user!.email![0].toUpperCase()}
            onClick= { (event) => {
              event.stopPropagation();
              setIsUserContextMenuVisible(!isUserContextMenuVisible);
            }}
        >
        </div>
        {
          isUserContextMenuVisible && 
          <div className='user-context-menu'>
            <button className='user-context-menu-item' onClick={()=>logout({ returnTo: window.location.origin })}>Logout</button>
          </div>
        }
      </>
    );
}

export default Login;