import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Login from './components/Login';
import MenuButton from './components/MenuButton';
import Navigation from './components/Navigation';
import Error from './components/ErrorMessage';

function App() {
  const {
    error,
    isLoading
  } = useAuth0();

  let [isNavVisible, _setNavVisibility] = useState(false);
  const isNavVisibleRef = useRef(isNavVisible);

  const setNavVisibility = (value: boolean) => {
    isNavVisibleRef.current = value;
    _setNavVisibility(value);
  };
  
  useEffect(()=> {
    const onDocumentClick = (event: MouseEvent) => {
      if(isNavVisibleRef.current && event.target instanceof Element && event.target.closest('nav') === null) {
        setNavVisibility(false);
      }
    };

    const eventType = 'click';
    document.addEventListener(eventType, onDocumentClick)

    return () => {
      document.removeEventListener(eventType, onDocumentClick)
    };
  }, []);

  if (isLoading)
  {
    return (<div>Loading...</div>)
  }

  return (
    <div className="AppContainer">
      <header>
        <MenuButton onClick={(event)=>{
            event.stopPropagation();
            setNavVisibility(!isNavVisible);
          }}/>
        <h1>Jordan Writes Terrible Code</h1>
        <Login />
      </header>
      <Navigation isVisible={isNavVisible} />
      <main>
        {error && <Error message={error.message}/>}
        <Outlet />
      </main>
    </div>
  );
}

export default App;
